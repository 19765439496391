@import './variables.scss';

.sidebar {
  background-color: #fff;
  position: sticky;
  top: 85px;
  bottom: 20px;
  margin-bottom: 20px;
  transition: top 0.2s;
  width: 250px;

  @include breakpoint-below($breakpoint-xl) {
    width: 200px;
  }

  @include breakpoint-below($breakpoint-lg) {
    display: none;
  }

  .project-name {
    text-transform: uppercase;
    display: block;
    line-height: 1.2;
    margin-bottom: 10px;
    color: $teal3;
    background: $white;
    padding: 4px;
  }
}

.sidebar.upper {
  transition: top 0.2s;
}

.sidebar-body {
  max-height: 65vh;
  overflow-y: auto;
  padding: 20px 10px;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
}

.sidebar-body .concepts-wrapper {
  margin-bottom: 36px;
}

.sidebar-header {
  background-color: $teal0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  padding: 20px 15px;
  text-transform: uppercase;
}

.sidebar-header .block-name {
  display: block;
  font-weight: 600;
}

.sidebar-header .block-name a {
  color: #fff;
}

.sidebar ul li {
  border-bottom: 1px solid $fog;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  cursor: pointer;

  a {
    color: $teal3;
    text-decoration: none;
    font-weight: 400;
    display: block;
    padding: 10px;
  }

  .active {
    color: #000;
    background-color: $clouds;
  }
}

.sidebar ul li:last-child {
  border-bottom: 0;
}
