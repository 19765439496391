.difficulty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid $lightbronze;
  text-transform: uppercase;
  flex-wrap: wrap;
  margin: 0 0 4px;
  padding: 15px 20px;
  width: 100%;

  @include breakpoint-below($breakpoint-sm) {
    padding: 5px;
  }
}

.difficulty-bullets {
  display: flex;
}

.difficulty-bullet {
  border: 1px solid $lightbronze;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 8px;

  &-full {
    @extend .difficulty-bullet;

    background-color: #f26727;
    border-color: #f26727;
  }
}

.difficulty-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  margin: 0 10px 0 0;
}

h5.difficulty-title {
  margin: 0 0 15px;
  display: flex;
  align-items: center;

  i {
    width: 20px;
    margin: 0 15px 0 0;
  }
}

.difficulty-row {
  display: flex;

  .difficulty {
    width: 100%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
