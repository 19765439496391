.teacher-block {
  margin: 25px 0 75px;
  background-color: $white;
  color: $black;
  border: 6px solid $lightbronze;
  border-radius: 3px;
  box-shadow: 0 0 20px grey;

  &--further {
    border-color: $teal2;

    .teacher-block-label {
      background-color: $teal2;
    }
  }

  code {
    font-size: 14px;
  }
}

.teacher-block-container {
  padding: 24px;

  @include breakpoint-below($breakpoint-sm) {
    padding: 10px;
  }
}

.teacher-block-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 400;
}

.teacher-block-label-container {
  text-align: right;
}

.teacher-block-label,
.markdown .teacher-block::before {
  display: inline-block;
  vertical-align: top;
  background-color: $lightbronze;
  color: $onyx;
  text-transform: uppercase;
  padding: 0 8px 6px 10px;
  font-size: 11px;
  font-family: monospase, sans-serif;
  font-weight: bold;
  line-height: 11px;
  cursor: pointer;
}

.teacher-block-link {
  font-size: 17px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  color: $teal1;
  font-weight: 600;
  font-family: monospase, sans-serif;
  line-height: 24px;

  i {
    @include size(20px);

    margin-right: 12px;
    fill: $teal4;
  }
}

.teacher-block-text,
.markdown .teacher-block {
  margin-bottom: 12px;

  p {
    font-size: 16px;
    line-height: 20px;
  }

  a {
    color: $teal3;
  }

  iframe {
    width: 100%;
  }

  ul li,
  ol li {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}

.teacher-blocks-toggle {
  @include size(48px);

  position: fixed;
  right: 20px;
  bottom: 130px;
  z-index: 5;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;

  i {
    height: 100%;
  }
}
