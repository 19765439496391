.info-column-box {
  padding: 20px 0;
  border-top: 1px solid $lightbronze;

  &:last-child {
    border-bottom: 1px solid $lightbronze;
    margin-bottom: 30px;
  }

  &:first-child {
    margin-top: 30px;
  }
}

.info-column-row {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    width: auto;
    margin: 0;
  }

  > .icon-estimated-time {
    width: 20px;
    margin-bottom: 0;
  }

  h5 {
    padding: 0 15px;
  }

  .info-column-estimated-time {
    padding: 0;
    background: none;
    border: none;
    font-weight: bold;
  }
}

h5 {
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 600;
}

h6 {
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 10px;
  font-weight: 600;
}

.info-column-estimated-time {
  background-color: #fff;
  border: 1px solid $lightbronze;
  display: block;
  padding: 6px 12px;
  text-transform: none;
  font-size: 12px;
  margin: 0;
  text-align: center;
  font-weight: 400;
}
