.lightbox {
  position: fixed;
  top: $header-height + 5px;
  bottom: 5px;
  left: 5px;
  right: 10px;
  border: 1px solid $lightbronze;
  background-color: white;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: auto;

  .step-block-img {
    max-width: 100%;
    max-height: 100%;
    height: 60vh;

    @include breakpoint-below($breakpoint-sm) {
      max-height: 70vh;
    }
  }

  .step-block-item {
    position: absolute;
    top: 5%;
    bottom: 5%;
    right: 8%;
    left: 8%;
    max-height: 70vh;

    @include breakpoint-below($breakpoint-md) {
      top: 50%;
      bottom: 0;
      right: 0;
      left: 0;
      transform: translateY(-70%);
    }
  }

  .step-block-stripe {
    position: relative;
  }

  .step-block-arrow {
    top: -70px;
  }
}
