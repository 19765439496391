@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/fonts';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';
@import '~highlight.js/styles/arduino-light.css';

#ino-header-new .app-brand-logo > img {
  max-width: 210px;
  max-height: 50px;
  height: auto;

  @include breakpoint-below($breakpoint-xl) {
    max-width: 190px;
    width: auto;
  }
}

#header:not(.with-buttons) #ino-header-new .platform-nav__right {
  @include breakpoint-above(1200px) {
    min-width: 210px;
    justify-content: flex-end;
  }
}

body {
  background: $clouds;
  min-width: 320px;

  * {
    box-sizing: border-box;

    &::after,
    &::before {
      box-sizing: border-box;
    }
  }

  &.wait {
    * {
      cursor: wait !important;
    }
  }
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;

  .headroom {
    transform: none !important;
    position: fixed !important;
  }

  .hljs {
    border: 5px solid #dae3e3;
    border-radius: 0;
    padding: 12px;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0;

    .hljs-comment {
      font-size: 13px;
    }

    .hljs-ln {
      min-width: 500px;
    }

    .hljs-ln-numbers {
      display: none;
    }

    .hljs-ln-n::before {
      color: #999;
      font-size: 13px;
      padding-right: 7px;
    }

    &.show-line-number {
      .hljs-ln-numbers {
        display: table-cell;
      }
    }
  }
}

.main-container {
  padding-top: 50px;
}

.app {
  background: $clouds;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $teal2;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $teal3;
  }

  > .container {
    flex: 1;
    margin: 0 auto;
    min-height: 80vh;
  }
}

.app .button,
.modal .button {
  @include ardu-store-button($teal3, $white);
}

.nav-bar {
  border-bottom: 3px solid #d0d4d4;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.3px;
  min-height: 100vh;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;

  &:not([src]),
  &[src=''] {
    visibility: hidden;
  }
}

pre {
  overflow: auto;
  white-space: pre-wrap;
  word-break: keep-all;
  margin-bottom: 30px;
  color: $teal2;
}

.divMyTable {
  color: white;
  line-height: 26.4px;
  display: grid;
  text-align: center;
  grid-row: 8;
  grid-column: 4;
  grid-gap: 10px;

  * {
    font-size: 16px;
    padding: 3px 2px;
  }
}

.divMyTable .divTableCell {
  line-height: 26.4px;
  border-width: 1px;
  border-color: black;
  background-color: #f4f4f4;
  color: $lightbronze;
  text-align: left;
}

.subHeader {
  background-color: #95a5a6;
  border-width: 1px;
  border-color: black;
}

.HeadName {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  border-width: 1px;
  border-color: black;
  background-color: #95a5a6;
}

.HeadNameEmpty {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 7;
  border-width: 1px;
  border-color: black;
}

.MJX-TEX {
  overflow-x: auto;
  max-width: 100%;
  overflow-y: hidden;
}

.intro-text__row {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-bottom: 15px;
}
