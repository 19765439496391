.formatted-content {
  ul,
  ol,
  blockquote {
    padding-left: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  table {
    width: 100%;
  }

  blockquote {
    font-style: italic;
    position: relative;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding-top: 0;
}
