.resource-link {
  border: 2px solid $clouds;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &__content {
    display: flex;
    align-items: center;
    width: 100%;

    @include breakpoint-below($breakpoint-sm) {
      display: block;
    }
  }

  &__title {
    font-size: 14px;
    text-transform: uppercase;
    padding: 13px 15px;
    background-color: $clouds;
    font-weight: 700;
    line-height: 1;
    color: #000;
  }

  &__resource {
    line-height: 12px;
    color: $teal3;
    padding: 0 15px;
  }
}
