.note-block {
  border-left: 3px solid $lightbronze;
  padding: 5px 15px;
  background-color: $clouds;

  &__description {
    font-size: 18px;
    line-height: 27px;
    display: block;
  }
}
